import React, { useState } from 'react'
import { Button, Dialog, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import CloseIcon from '@material-ui/icons/Close'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Video from '../components/video/Video'

import Vision from '../assets/svg/vision.svg'
import PlantGuy from '../assets/svg/plantGuy.svg'
import AntreJeune from '../assets/svg/contributions/antreJeune.svg'
import ProjetHarmonie from '../assets/svg/contributions/projetHarmonie.svg'

function Contribution() {
    const { t } = useTranslation('contribution')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const onClose = () => setIsModalOpen(false)

    return (
        <Layout>
            <SEO title="contribution:title" description="contribution:description" />
            <h1 className="contributionsTitle">{t('header')}</h1>
            <div className="ourDreamSection">
                <div>
                    <h3 className="contributionTitle">{t('ourDream')}</h3>
                    <p className="contributionParagraph">{t('ourDreamDescription')}</p>
                </div>
                <div className="logoContainer">
                    <Vision />
                </div>
            </div>
            <div className="realitySection">
                <div>
                    <h3 className="contributionTitle">{t('reality')}</h3>
                    <p className="contributionParagraph">{t('realityDescription')}</p>
                </div>
                <div className="logoContainer">
                    <PlantGuy />
                </div>
            </div>

            <h2 className="contributionsHeader">{t('contributions')}</h2>
            <div className="card drinkCard">
                <h3 className="cardTitle">{t('dontDrink')}</h3>
                <div className="card contributionVideoPreview">
                    <Button
                        size="large"
                        variant="contained"
                        startIcon={<PlayArrowIcon />}
                        disableElevation
                        onClick={() => setIsModalOpen(true)}
                        classes={{ root: 'watchButtonRoot', contained: 'watchButtonContained' }}
                    >
                        {t('misc:watch')}
                    </Button>
                </div>
            </div>
            <div className="antreJeuneSection">
                <div>
                    <h3 className="contributionTitle">{t('antreJeune')}</h3>
                    <p className="contributionParagraph">{t('antreJeuneDescription')}</p>
                </div>
                <div className="logoAndButton">
                    <AntreJeune />
                    <Button
                        size="large"
                        variant="contained"
                        disableElevation
                        href="https://lantre-jeunes.com/"
                        classes={{
                            root: 'antreJeuneButtonRoot',
                            contained: 'antreJeuneButtonContained',
                        }}
                    >
                        {t('antreJeuneButton')}
                    </Button>
                </div>
            </div>
            <div className="harmonySection">
                <div>
                    <h3 className="contributionTitle">{t('harmony')}</h3>
                    <p className="contributionParagraph">{t('harmonyDescription')}</p>
                </div>
                <div className="logoAndButton">
                    <ProjetHarmonie />
                    <Button
                        size="large"
                        variant="contained"
                        disableElevation
                        href="https://projetharmonie.ca/"
                        classes={{
                            root: 'harmonyButtonRoot',
                            contained: 'harmonyButtonContained',
                        }}
                    >
                        {t('harmony')}
                    </Button>
                </div>
            </div>
            <div className="card supportCard">
                <h3 className="cardTitle">{t('support')}</h3>
                <p className="cardParagraph">{t('supportDescription')}</p>
                <Button
                    size="large"
                    variant="contained"
                    disableElevation
                    href="https://www.patreon.com/extrapole"
                    classes={{ root: 'supportButtonRoot', contained: 'supportButtonContained' }}
                >
                    {t('supportButton')}
                </Button>
            </div>
            <Dialog
                open={isModalOpen}
                onClose={onClose}
                aria-labelledby="dialog-video"
                BackdropProps={{ className: 'modalBackdrop' }}
                classes={{ root: 'videoModal', paper: 'videoPaper' }}
                TransitionProps={{ unmountOnExit: true }}
                maxWidth="md"
            >
                <Video url="https://vimeo.com/658658573" />
                <IconButton onClick={onClose} className="closeIcon">
                    <CloseIcon />
                </IconButton>
            </Dialog>
        </Layout>
    )
}

export default Contribution
